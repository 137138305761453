<template>

	<div class="ele-body">
		<el-card shadow="never">
			<!-- 搜索表单 -->
			<el-form :model="table.where" class="ele-form-search "
				@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
				<div class='d-flexspabet'>
					<div>
						<!--            <el-button @click="showEdit=true" class="ele-btn-icon addbtn ele-form-actions" size="small">添加管理员</el-button>-->
					</div>
					<div class="d-flex">
						<el-form-item label="省:" label-width="35px" class="w-150">
							<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" clearable>
								<el-option v-for="option in provArr" :label='option.name' :value="option.pid"
									:key="option.pid"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="市:" label-width="35px" class="w-150">
							<el-select v-model="table.where.cid"
								@change="handleChangeCity(table.where.cid),$forceUpdate()" clearable>
								<el-option v-for="option in cityArr" :label='option.name' :value="option.cid"
									:key="option.cid"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="县市区:" label-width="62px" class="w-200">
							<el-select v-model="table.where.aid" @change="$forceUpdate()" clearable>
								<el-option v-for="option in districtArr" :label='option.name' :value="option.aid"
									:key="option.aid"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="管理员角色:" label-width="90px" class="w-200">
							<el-select v-model="table.where.type" clearable>
								<el-option v-for="option in adminList" :label="option.name" :value="option.type"
									:key="option.type"></el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="是否审核:" label-width="77px" class="w-200 mr-20">
							<el-select v-model="table.where.is_check" clearable>
								<el-option v-for="option in isCheckList" :label="option.name" :value="option.type"
									:key="option.type"></el-option>
							</el-select>
						</el-form-item>
						<!-- <el-form-item label="状态:" label-width="48px" class="w-150">
							<el-select v-model="table.where.status">
								<el-option value="1" label="正常"></el-option>
								<el-option value="2" label="禁用"></el-option>
							</el-select>
						</el-form-item> -->
						<el-form-item>
							<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
								class="ele-btn-icon ele-form-actions">搜索</el-button>
							<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
						</el-form-item>
					</div>
				</div>
			</el-form>
			<!-- 数据表格 -->
			<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
				highlight-current-row>
				<template slot-scope="{index}">
					<el-table-column type="selection" width="45" align="center" fixed="left" />
					<el-table-column type="index" :index="index" label="序号" width="60" align="center" fixed="left"
						show-overflow-tooltip />
					<el-table-column prop="role_name" label="管理员权限" show-overflow-tooltip min-width='120px' />
					
				
					<el-table-column prop="type" label="管理员角色" show-overflow-tooltip min-width='100px'>
						<template slot-scope="scope">
							{{scope.row.type==1?'总管理':scope.row.type==2?'省代理':scope.row.type==3?'市代理':scope.row.type==4?'县运营':scope.row.type==5?'广告商':''}}
						</template>
					</el-table-column>
					<el-table-column prop="address" label="所辖地区" show-overflow-tooltip min-width='120px' />
					<el-table-column prop="login_ip" label="上次登录ip" show-overflow-tooltip min-width='120px' />
					<el-table-column prop="login_num" label="登录次数(次)" show-overflow-tooltip />
					<el-table-column label="上次登录时间" show-overflow-tooltip min-width='110px'>
						<template slot-scope="{row}">{{ row.login_time*1000 | toDateString }}</template>
					</el-table-column>
					<el-table-column prop="real_name" label="真实姓名" show-overflow-tooltip />
					<el-table-column prop="mobile" label="联系方式" show-overflow-tooltip min-width="110" />
          
         
          
					<el-table-column label="申请时间" show-overflow-tooltip min-width='110px'>
						<template slot-scope="{row}">{{ row.activation_time*1000 | toDateString }}</template>
					</el-table-column>
          
          
					<!-- <el-table-column label="审核时间" show-overflow-tooltip min-width='110px'>
						<template slot-scope="{row}">{{ row.activation_time*1000 | toDateString }}</template>
					</el-table-column> -->

<!--					<el-table-column prop="gender_name" label="审核状态" show-overflow-tooltip />-->
          <el-table-column prop="is_check" label="审核状态" show-overflow-tooltip min-width='100px'>
            <template slot-scope="scope">
              {{scope.row.is_check==2?'已驳回':'待审核'}}
            </template>
          </el-table-column>
					<el-table-column label="操作" width="240px" align="center" :resizable="false" fixed="right">
						<template slot-scope="{row}">
							<el-link v-if="row.is_check!==2" @click="admin_status(row.id,1)" slot="reference" icon="el-icon-success"
								type="primary" :underline="false">通过</el-link>
							<el-link v-if="row.is_check!==2" @click="admin_status(row.id,2)" slot="reference" icon="el-icon-error" type="danger"
								:underline="false">拒绝</el-link>
							<el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger" :underline="false">删除</el-link>
						</template>
					</el-table-column>
				</template>
			</ele-data-table>
		</el-card>
	</div>
</template>
<script>
	export default {
		name: "SysUser",
		data() {
			return {
				table: {
					url: '/admin/index',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				showEdit: false, // 是否显示表单弹窗
				editForm: {}, // 表单数据
				editRules: { // 表单验证规则
					type_name: [{
						required: true,
						message: '请在列表中选择一项',
						trigger: 'blur'
					}],
					realname: [{
						required: true,
						message: '请输入用户姓名',
						trigger: 'blur'
					}],
					gender: [{
						required: true,
						message: '请选择性别',
						trigger: 'blur'
					}],
					password: [{
						required: true,
						message: '请输入密码',
						trigger: 'blur'
					}]
				},
				roleList: [], // 角色列表
				levelList: [], // 职级列表
				positionList: [], // 岗位列表
				provArr: [],
				cityArr: [],
				districtArr: [],
				adminList: [{
					type: 2,
					name: '省代理'
				}, {
					type: 3,
					name: '市代理'
				}, {
					type: 4,
					name: '县运营'
				}, {
					type: 5,
					name: '广告商'
				}],
				isCheckList: [{
					type: 1,
					name: '待审核'
				}, {
					type: 3,
					name: '已驳回'
				}]
			}
		},
		mounted() {
			this.queryRoles(); // 查询角色列表
			this.getLevelList(); // 查询职级列表
			this.getPositionList(); // 查询岗位列表
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				var data = JSON.parse(res.data)
				this.provArr = data
			})

		},
		methods: {
			/**
			 *选择省
			 **/
			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''



				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				var cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},
			handleChangeProv1(e) {
				if(e){
				/** 获取被选省省份的pid**/
				this.changePro = true
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cname = ''
					this.editForm.aname = ''
				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},
			/**
			 *选择市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				this.changePro = true
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''

				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},
			/* 显示编辑 */
			edit(row) {
				this.editForm = Object.assign({}, row, {
					role_ids: row.roles.map(d => d.id)
				});
				this.showEdit = true;
			},
			/* 保存编辑 */
			save() {
				this.$refs['editForm'].validate((valid) => {
					if (valid) {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/admin/edit', this.editForm).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.showEdit = false;
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					} else {
						return false;
					}
				});
			},
			/* 删除 */
			remove(row) {
				// if (!row) { // 批量删除
				// 	if (this.choose.length === 0) return this.$message.error('请至少选择一条数据');
				// 	let ids = this.choose.map(d => d.id);
				// 	this.$confirm('确定要删除审核记录?', '提示', {
				// 		type: 'warning'
				// 	}).then(() => {
				// 		const loading = this.$loading({
				// 			lock: true
				// 		});
				// 		this.$http.post('/admin/delete', {
				// 			id: ids
				// 		}).then(res => {
				// 			loading.close();
				// 			if (res.data.code === 0) {
				// 				this.$message({
				// 					type: 'success',
				// 					message: res.data.msg
				// 				});
				// 				this.$refs.table.reload();
				// 			} else {
				// 				this.$message.error(res.data.msg);
				// 			}
				// 		}).catch(e => {
				// 			loading.close();
				// 			this.$message.error(e.message);
				// 		});
				// 	}).catch(() => 0);
				// } else { // 单个删除
					this.$confirm('确定要删除审核记录?', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/admin/delete', {
							id: row.id
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				// }
			},
			/**
			 * 重置密码
			 */
			resetPwd(row) {
				const loading = this.$loading({
					lock: true
				});
				this.$http.post('/admin/resetPwd', {
					id: row.id
				}).then(res => {
					loading.close();
					if (res.data.code === 0) {
						this.$message({
							type: 'success',
							message: res.data.msg
						});
						this.$refs.table.reload();
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					loading.close();
					this.$message.error(e.message);
				});
			},
			/* 更改状态 */
			admin_status(id, check_status) {
				if (check_status == 1) {
					this.$confirm('确认通过该审核？', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/admin/status', {
							id: id,
							status: check_status
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: res.data.msg
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
				if (check_status == 2) {
					this.$confirm('确认驳回该审核？', '提示', {
						type: 'warning'
					}).then(() => {
						const loading = this.$loading({
							lock: true
						});
						this.$http.post('/admin/status', {
							id: id,
							status: check_status
						}).then(res => {
							loading.close();
							if (res.data.code === 0) {
								this.$message({
									type: 'success',
									message: '驳回成功'
								});
								this.$refs.table.reload();
							} else {
								this.$message.error(res.data.msg);
							}
						}).catch(e => {
							loading.close();
							this.$message.error(e.message);
						});
					})
				}
			},
			/* 查询角色列表 */
			queryRoles() {
				this.$http.get('/role/getRoleList').then(res => {
					if (res.data.code === 0) {
						this.roleList = res.data.data;
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				});
			},
			/**
			 * 获取职级列表
			 */
			getLevelList() {
				this.$http.get('/level/getLevelList').then(res => {
					if (res.data.code === 0) {
						this.levelList = res.data.data;
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				});
			},
			/**
			 * 获取岗位列表
			 */
			getPositionList() {
				this.$http.get('/position/getPositionList').then(res => {
					if (res.data.code === 0) {
						this.positionList = res.data.data;
					} else {
						this.$message.error(res.data.msg);
					}
				}).catch(e => {
					this.$message.error(e.message);
				});
			}
		}
	}
</script>

<style scoped>
	.ele-block>>>.el-upload,
	.ele-block>>>.el-upload-dragger {
		width: 100%;
	}

	/* 用户资料卡片 */
	.user-info-card {
		padding-top: 8px;
		text-align: center;
	}

	.user-info-card .user-info-avatar-group {
		position: relative;
		cursor: pointer;
		margin: 0 auto;
		width: 110px;
		height: 110px;
		border-radius: 50%;
		overflow: hidden;
	}

	.user-info-card .user-info-avatar {
		width: 110px;
		height: 110px;
		border-radius: 50%;
		object-fit: cover;
	}

	.user-info-card .user-info-avatar-group>i {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		color: #FFF;
		font-size: 30px;
		display: none;
		z-index: 2;
	}

	.user-info-card .user-info-avatar-group:hover>i {
		display: block;
	}

	.user-info-card .user-info-avatar-group:hover:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, .3);
	}
</style>
